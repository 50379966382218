html {
    overflow-x: hidden
}

body {
    font-size: 16px;
    line-height: 1.5;
    margin: 0;
    padding: 0;
    background-color: #0A0A0B;
    color: #fff;
}

* {
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
}